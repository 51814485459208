import React from "react";


const Details = () => {
  return (
    <>
    <h1>Details</h1>
    </>
  );
};

export default Details;