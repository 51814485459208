import React from "react";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import '../Css/Navbar.css'
import Widgets from "./Widgets";
import Details from "./Details";

const Dashboard = () => {
  return (
    <>
     <div className="zps-main container-fluid">
        <div className="zps-main-titlearea">
        <div className="zps-main-titlearea">
            <nav id="module_tabs" role="navigation" className="zps-title-content" tabIndex="0">
                <div className="project-uppertabs-parent active" data-zpqa="upperTabs-Widgets" app-name="Widgets"
                    tab-name="Widgets">
                      <span className="project-uppertabs-linkwrap" data-tooltip="Widgets" ellipsis="true" aria-label="Widgets"><Link  to='/dashboard/widgets' className="Projet-link">Widgets</Link></span>
                </div>

                <div className="project-uppertabs-parent" data-zpqa="upperTabs-Details" app-name="Details" tab-name="Details">
                  <span  className="project-uppertabs-linkwrap" data-tooltip="Details" ellipsis="true" aria-label="Details"><Link to="/dashboard/details" className="Projet-link">Details</Link></span>
                </div>

                <div className="project-uppertabs-parent" data-zpqa='Comments' app-name='Comments' tab-name="projectreports">
                  <span  className="project-uppertabs-linkwrap"data-tooltip="Comments" ellipsis="true" aria-label="Comments"><Link to="/dashboard/comments" className="Projet-link">Comments</Link></span>
                </div>

                <div className="project-uppertabs-parent" data-zpqa="upperTabs-Activity Stream<" app-name="Activity Stream<" tab-name="Activity Stream<">
                  <span  className="project-uppertabs-linkwrap" data-tooltip="Activity Stream<" ellipsis="true" aria-label="Activity Stream<"><Link to="/dashboard/Activitystream" className="Projet-link">Activity Stream</Link></span>
                </div>
            </nav>
          </div>
        </div>
      </div>
      <Outlet />

    </>
  );
};

export default Dashboard;
