import React from "react";
import '../Css/Navbar.css'
import { Link, NavLink } from "react-router-dom";


const navbar = () => {
  return (
<>
    <header className="zps-main-topbar" ms-tab="hidden">
        <div className="zps-main">
        <div className="zps-main-titlearea">
        <div className="zps-main-titlearea">
            <nav id="module_tabs" role="navigation" className="zps-title-content" tabIndex="0">
            <span className="icon" id="projectNameInside">
                <a  data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                  <i id="offcanvas" className="fa fa-bars"></i>
                </a> 
            </span>
              <span className="topbar-project-name" id="projectNameInside">
                <span ellipsis="true"data-tooltip="Sample Website Development Project"aria-label="Sample Website Development Project">Sample Website Development Project</span>
              </span>
                <div className="project-uppertabs-parent active" data-zpqa="upperTabs-Dashboard" app-name="Dashboard"
                    tab-name="dashboard">
                      <span className="project-uppertabs-linkwrap" data-tooltip="Dashboard" ellipsis="true" aria-label="Dashboard"><Link  to='/dashboard' className="Projet-link">Dashboard</Link></span>
                </div>

                <div className="project-uppertabs-parent" data-zpqa="upperTabs-Tasks" app-name="Tasks" tab-name="kanbanview">
                  <span  className="project-uppertabs-linkwrap" data-tooltip="Tasks" ellipsis="true" aria-label="Tasks"><Link to="/tasks" className="Projet-link">Tasks</Link></span>
                </div>

                <div className="project-uppertabs-parent" data-zpqa="upperTabs-Gantt &amp; Reports"app-name="Gantt &amp; Reports" tab-name="projectreports">
                  <span  className="project-uppertabs-linkwrap"data-tooltip="Gantt &amp; Reports" ellipsis="true" aria-label="Gantt &amp; Reports"><Link to="/report" className="Projet-link">Gantt &amp;Reports</Link></span>
                </div>

                <div className="project-uppertabs-parent" data-zpqa="upperTabs-Documents" app-name="Documents" tab-name="documents">
                  <span  className="project-uppertabs-linkwrap" data-tooltip="Documents" ellipsis="true" aria-label="Documents"><Link to="/documents" className="Projet-link">Documents</Link></span>
                </div>

                <div className="project-uppertabs-parent" data-zpqa="upperTabs-Documents" app-name="Documents" tab-name="documents">
                  <span  className="project-uppertabs-linkwrap" data-tooltip="Documents" ellipsis="true" aria-label="Documents"><Link to="/milestones" className="Projet-link">Milestones</Link></span>
                </div>

                <div className="project-uppertabs-parent" data-zpqa="upperTabs-Documents" app-name="Documents" tab-name="documents">
                  <span  className="project-uppertabs-linkwrap" data-tooltip="Documents" ellipsis="true" aria-label="Documents"><Link to="/timesheet" className="Projet-link">Timesheet</Link></span>
                </div>

                <div className="project-uppertabs-parent" data-zpqa="upperTabs-Documents" app-name="Documents" tab-name="documents">
                  <span to="/issues" className="project-uppertabs-linkwrap" data-tooltip="Documents" ellipsis="true" aria-label="Documents"><Link to="/issues" className="Projet-link">Issues</Link></span>
                </div>

                  <div className="project-uppertabs-parent" data-zpqa="upperTabs-Documents" app-name="Documents"tab-name="documents">
                    <span  className="project-uppertabs-linkwrap" data-tooltip="Documents" ellipsis="true" aria-label="Documents"><Link to="/users" className="Projet-link">Users</Link></span>
                </div>
         </nav>
      </div>
        </div>
        </div>
    </header><hr></hr>

    <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div className="offcanvas-header">
      <div className="zps-panel-logo">
      <a data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
      <i id="offcanvas" className="fa fa-bars"></i>
      </a>
      <img className="img" src="https://js.zohocdn.com/projects/images/svg/zps-logo-dark_e3e77_.svg" height="30" width="130"></img><span>Project</span>
      </div>
  </div>
  <hr></hr>
  
 <nav className="zps-default-items" id="globalMenuHolder" role="navigation">
        <ul className="zps-items-list">
          <li><a href="#home">Home</a></li>
          <li><a href="#news">Feed</a></li>
          <li><a href="#contact">Discuss</a></li>
          <li><a href="#about">Reports</a></li>
          <li><a href="#about">Calendar</a></li>
          <li><a href="#about">Projects</a></li>
        </ul>
  </nav>
  <nav className="zps-default-items" id="globalMenuHolder" role="navigation">
    <div className="btn-group">
        <h4 type="button" className=" WorkOverview" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
        WORK OVERVIEW
        </h4> 
        <ul className="dropdown-menu dropdown-menu-lg-end">
          <li className="zps-list-name"><a href="">Tasks</a></li>
          <li className="zps-list-name"><a href="">Issues</a></li>
          <li className="zps-list-name"><a href="">Milestones</a></li>
          <li className="zps-list-name"><a href="">Timesheets</a></li>
        </ul>
    </div>
  </nav>
    <div>
        <ul>
          <li className="global-allProjects"><a href="#">RECENT PROJECTS</a></li>
          <li className="global-allProjects"><a href="#">SAMPLE WEBSITE DEVELOPMENT PROJECTS</a></li>
        
        </ul>
    </div>
</div>


    </>    
  );
};

export default navbar;
