import React from "react";


const Issues = () => {
  return (
<>
<h1>Issues</h1>
</>
    
 );
};

export default Issues;