import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Tasks from "./components/Tasks";
import Dashboard from "./components/Dashboard";
import Documents from "./components/Documents";
import Timesheet from "./components/Timesheet";
import Issues from "./components/Issues";
import Users from "./components/Users";
import Milestones from "./components/Milestones";
import Reports from "./components/Reports";
import Widgets from "./components/Widgets";
import Details from "./components/Details";
import Comments from "./components/Comments";
import ActivityStream from "./components/ActivityStream";

function App() {
  return (
    <>
    <div>
      <Navbar />
      <Routes>
        <Route path="/dashboard" exact Component={Dashboard}>
        <Route path="/dashboard/widgets" exact Component={Widgets} />
        <Route path="/dashboard/details" exact Component={Details} />
        <Route path="/dashboard/comments" exact Component={Comments} />
        <Route path="/dashboard/activitystream" exact Component={ActivityStream} />
        </Route>
        <Route path="/tasks" exact Component={Tasks} />
        <Route path="/documents" exact Component={Documents} />
        <Route path="/report" exact Component={Reports} />
        <Route path="/milestones" exact Component={Milestones} />
        <Route path="/timesheet" exact Component={Timesheet} />
        <Route path="/issues" exact Component={Issues} />
        <Route path="/users" exact Component={Users} />
      </Routes>
    </div>
    </>
  );
}

export default App;
