import React from "react";


const Reports = () => {
  return (
<>
<h1>Gantt && Reports</h1>
</>
    
 );
};

export default Reports;