import React from "react";


const Comments = () => {
  return (
    <>
    <h1> Comments </h1>
    </>
  );
};

export default Comments;