import React from "react";


const Milestones = () => {
  return (
<>
<h1>Milestones</h1>
</>
    
 );
};

export default Milestones;