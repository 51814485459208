import React from "react";


const ActivityStream = () => {
  return (
    <>
    <h1> Activity Stream </h1>
    </>
  );
};

export default ActivityStream;