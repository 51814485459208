import React from "react";
import "../Css/Widgets.css";


const Widgets = () => {
  return (
    <>
        <div className="dash_innerbox container-fluid">
        <div className="row">
          <div className="wg-title-cover col-6">
            <div className="zps-pop-container">
              <h1>Task Status</h1>
            </div>
          </div>

          <div className="wg-title-cover col-6">
            <div className="zps-pop-container">
              <h1>Overdue Work Items</h1>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="wg-title-cover col-6">
            <div className="zps-pop-container">
              <h1>Team Status</h1>
            </div>
          </div>

          <div className="wg-title-cover col-6">
            <div className="zps-pop-container">
              <h1>Team Status</h1>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="wg-title-cover col-2">
            <div className="zps-pop-container">
              <h1>Tags</h1>
            </div>
          </div>

          <div className="wg-title-cover col-5">
            <div className="zps-pop-container">
              <h1>Weekly Digest</h1>
            </div>
          </div>
          <div className="wg-title-cover col-5">
            <div className="zps-pop-container">
              <h1>Top 5 Go-getters</h1>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="wg-title-cover col-2">
            <div className="zps-pop-container">
              <h1>Top 5 Issue Fixers</h1>
            </div>
          </div>

          <div className="wg-title-cover col-5">
            <div className="zps-pop-container">
              <h1>Milestone Status</h1>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="wg-title-cover col-6">
            <div className="zps-pop-container">
              <h1>Upcoming Events</h1>
            </div>
          </div>

          <div className="wg-title-cover col-6">
            <div className="zps-pop-container">
              <h1>Upcoming Work Items</h1>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="wg-title-cover col-6">
            <div className="zps-pop-container">
              <h1>Today's Work Items</h1>
            </div>
          </div>

          <div className="wg-title-cover col-6">
            <div className="zps-pop-container">
              <h1>Task Progress Chart</h1>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="wg-title-cover col-2">
            <div className="zps-pop-container">
              <h1>Timesheet Summary</h1>
            </div>
          </div>

          <div className="wg-title-cover col-5">
            <div className="zps-pop-container">
              <h1>Task - Planned Vs Actual</h1>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="wg-title-cover col-6">
            <div className="zps-pop-container">
              <h1>Tasks for My Team members</h1>
            </div>
          </div>

          <div className="wg-title-cover col-6">
            <div className="zps-pop-container">
              <h1>Issues for my team members</h1>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Widgets;
